<template>
    <div class="section-header">
        <h1 v-if="!link">{{ title }}</h1>
        <router-link v-else :to="link"><h1>{{ title }}</h1></router-link>
    </div>
</template>
<script>
export default {
    name: 'SectionHeader',

    props: {
        title: String,
        link: String
    }
}
</script>